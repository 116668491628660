:root {
  --light-bg: #DCE1DE;
  --dark-bg: #051923;
  background-color: var(--dark-bg);
}

.rules-dialog a:focus {
 outline: none ;
}

.config-panel canvas {
  border-radius: 5px;
}

.logo-beta {
  border-radius: 20px;
  background-color: #C75000;
  padding: 3px 8px 5px;
}

.config-panel {
  margin: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.config-panel .mdc-list-divider {
  margin: 16px -16px 16px -16px;
}

.mdc-dialog__content .mdc-list-divider {
  margin: 8px -24px 8px -24px;
}

.config-panel .mdc-card {
  width: 90vw;
  max-width: 500px;
}

.config-panel .rmwc-data-table {
  width: 100%;
  max-width: 500px;
}

.config-panel .rmwc-data-table__content {
  width: 100%;
}

.config-panel button {
  margin: 0px 8px 0px 0px;
}

.config-panel .mdc-text-field {
  width: 100%;
}

.spaced {
  margin: 5px 0px;
}

.centered {
  text-align: center;
}

.light-bg {
  background-color: var(--light-bg);
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
}

/* RMWC */

.mdc-floating-label {
  color: #003553 !important;
}

.mdc-linear-progress__bar-inner {
  background-color: #00A6FB;
}

/* buffer dots show up on mobile even when buffer not set */
.mdc-linear-progress {
  background-color: var(--light-bg);
}
.mdc-linear-progress__buffering-dots {
  background-image: unset;
}

.mdc-card {
  padding: 16px;
}

.mdc-top-app-bar__row .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
  border-color: white;
  background-color: transparent;
}

.mdc-top-app-bar {
  position: relative;
  height: 120px;
}

/* App */

.App {
  height: calc(var(--vh, 1vh) * 100);
}

.logo {
  color: white;
  margin-top: 20px;
}

/* PlayGame */
.crew-count button {
  background-color: white;
}

.traitor-count button {
  background-color: black;
  color: white;
}

.turn-count {
  margin-right: 20px;
  margin-left: 20px;
}

.bar-space {
  height: 175px;
}

.content-space {
  height: calc(var(--vh, 1vh) * 100 - 175px);
  overflow-y: scroll;
  margin-right: -50px;
  padding-right: 50px;
}

.rules-dialog a:link, a:visited {
    color: #85929E;
}

.rules-dialog a:hover {
    color: #85929E;
}

/* CreateGame */

.coil-info {
  color: white;
}

.coil-info img {
  position: relative;
  top: 4px;
  width: 20px;
}

.coil-info a {
    text-decoration: none;
}

.coil-info a:link, a:visited {
    color: #85929E;
}

.coil-info a:hover {
    color: #85929E;
}

.feedback-info {
  color: white;
}

.feedback-info a {
    text-decoration: none;
}

.feedback-info a:link, a:visited {
    color: #85929E;
}

.feedback-info a:hover {
    color: #85929E;
}

.create-buttons hr {
  border: 1px solid white;
  width: 100px;
}

/* MessagesView */

.messages-body {
  display: flex;
  flex-direction: column;
  height: calc(var(--vh, 1vh) * 100 - 175px);
  overflow: hidden;
}

.messages-list {
  flex-grow: 1;
  overflow-y: scroll;
  margin-right: -50px;
  padding-right: 50px;
  background-color: #505A5B;
}

.messages-controls .mdc-select {
  width: 100%;
}

.messages-controls .mdc-text-field {
  width: 100%;
}

.messages-controls .mdc-text-field i:focus {
 outline: none !important;
}

.message-bubble {
  border: 0px solid black;
  border-radius: 5px;
  padding: 10px;
  max-width: 400px;
  position: relative;
}

.own-message-bubble {
  margin: 5px 5px 20px 50px;
  color: black;
  background-color: #A2D729;
}

.own-message-bubble::after {
    border-left: 15px solid transparent;
    border-top: 15px solid #A2D729;
    bottom: -15px;
    content: "";
    position: absolute;
    right: 20px;
}

.other-message-bubble {
  margin: 5px 50px 20px 5px;
  color: black;
  background-color: white;
}

.other-message-bubble::after {
    border-left: 15px solid white;
    border-bottom: 15px solid transparent;
    bottom: -15px;
    content: "";
    position: absolute;
    left: 20px;
}


/* Dead */

.player-dead {
  margin-top: 50px;
}

.player-dead-character {
  margin-top: 50px;
}

.player-dead-character .head {
  position: relative;
  left: 40px;
  top: 60px;
}

.player-dead-character .wrench {
  position: relative;
  right: 40px;
}

.player-dead-character .material-icons {
  font-size: 150px;
  color: white;
}

.dead-animate i {
  animation: rotate 20s linear infinite;
}
@keyframes rotate {
  to{ transform: rotate(360deg); }
}

.dead-animate {
  animation: shrink 10s linear;
  animation-fill-mode: forwards;
}
@keyframes shrink {
  to{ transform: scale(0); }
}
